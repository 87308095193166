var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-bottom-navigation",
    { attrs: { value: _vm.value, color: "primary" } },
    [
      _c(
        "v-btn",
        [
          _c("span", [_vm._v("Recents")]),
          _c("v-icon", [_vm._v("mdi-history")]),
        ],
        1
      ),
      _c(
        "v-btn",
        [
          _c("span", [_vm._v("Favorites")]),
          _c("v-icon", [_vm._v("mdi-heart")]),
        ],
        1
      ),
      _c(
        "v-btn",
        [
          _c("span", [_vm._v("Nearby")]),
          _c("v-icon", [_vm._v("mdi-map-marker")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }