var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      ref: "drawer",
      staticClass: "FormWrapper",
      attrs: {
        permanent: _vm.value,
        value: _vm.value,
        width: _vm.width,
        app: "",
        fixed: "",
        flat: "",
        right: "",
        temporary: "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "append",
            fn: function () {
              return [
                _c("v-divider", { attrs: { dark: "" } }),
                _c(
                  "div",
                  { staticClass: "fill-width d-flex flex-row" },
                  [_vm._t("actions")],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            slot: "prepend",
            height: _vm.appHeaderHeight,
            color: "primary darken-1",
            dark: "",
          },
          slot: "prepend",
        },
        [_c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")])],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "overflow-y-auto",
          attrs: { flat: _vm.flat, height: "100%" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [_vm._t("content")],
            2
          ),
        ],
        1
      ),
      _c("VLoading", { attrs: { value: _vm.loading, absolute: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }