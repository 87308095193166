var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height d-flex flex-column align-center justify-center",
    },
    [
      _c("v-expand-transition", [
        _vm.appMultipleTabs
          ? _c(
              "div",
              { staticClass: "fill-width" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      height: 56,
                      "show-arrows": "",
                      "slider-color": "primary darken-1",
                    },
                    on: { change: _vm.handleTabsChange },
                  },
                  _vm._l(_vm.openedRoutes, function (route, index) {
                    return _c(
                      "v-tab",
                      {
                        key: route.name,
                        attrs: {
                          exact: route.name === _vm.$route.name,
                          to: route.fullPath,
                        },
                        on: {
                          contextmenu: function ($event) {
                            return _vm.handleCtxMenu($event, index)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "subtitle-1" }, [
                          _vm._v(_vm._s(route.name)),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              ripple: "",
                              small: "",
                              text: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleClose(index)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(" close "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c("v-divider"),
                _c("v-breadcrumbs", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$vuetify.breakpoint.xsOnly,
                      expression: "!$vuetify.breakpoint.xsOnly",
                    },
                  ],
                  staticClass: "pt-2 pb-2",
                  attrs: { items: _vm.breadcrumbs },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "divider",
                        fn: function () {
                          return [_c("v-icon", [_vm._v("forward")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3506412448
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "fill-width flex-grow-1",
          style: { position: "relative" },
        },
        [
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll.self",
                  value: (e) => _vm.updateScrollTop(e.target.scrollTop),
                  expression: "e => updateScrollTop(e.target.scrollTop)",
                  modifiers: { self: true },
                },
              ],
              ref: "content",
              staticClass: "overflow-x-hidden overflow-y-auto py-1 px-1",
              style: {
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              attrs: { fluid: "" },
            },
            [
              _c(
                "div",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-slide-x-transition",
                    { attrs: { mode: "out-in" } },
                    [
                      _c(
                        "keep-alive",
                        {
                          attrs: {
                            include: _vm.appMultipleTabs
                              ? _vm.openedRoutesComponentNames
                              : [],
                          },
                        },
                        [_c("router-view", { key: _vm.$route.name })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "VFollowMenu",
        { ref: "followMenu" },
        [
          _c(
            "v-list",
            { staticClass: "py-0", attrs: { dense: "" } },
            _vm._l(_vm.menus, function (item, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  attrs: { dense: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return item.click.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { size: 16, small: "", tag: "span" },
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  ),
                  _c("v-list-item-title", [_vm._v(_vm._s(item.title))]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }