var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueImgInputer", {
    ref: "vueImgInputer",
    class: _vm.flat ? "elevation-0" : "",
    attrs: {
      "img-src": _vm.imgSrc,
      "max-size": _vm.maxSize,
      "no-hover-effect": _vm.readonly,
      "on-error": _vm.onUploadError,
      "on-progress": _vm.onUploadSProgress,
      "on-start": _vm.onUploadStart,
      "on-success": _vm.onUploadSuccess,
      placeholder: `${_vm.placeholder}（maximun${_vm.maxMB}M）`,
      readonly: _vm.readonly,
      size: _vm.size,
      accept: "image/*",
      icon: "img",
      "readonly-tip-text": "",
      theme: "material",
    },
    on: { onChange: _vm.fileChange, onExceed: _vm.overMaxSize },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v
      },
      expression: "file",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }