var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toast" },
    [
      _c(
        "v-fade-transition",
        { attrs: { group: "", mode: "out-in" } },
        _vm._l(_vm.items, function (item, i) {
          return _c(
            "v-snackbar",
            {
              key: item.id,
              attrs: {
                color: item.color,
                dark: _vm.dark,
                timeout: item.timeout,
                top: true,
                value: i === 0,
                absolute: "",
                app: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _vm.items.length > 1
                          ? _c(
                              "v-btn",
                              _vm._b(
                                {
                                  attrs: { color: "white", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dequeue(item.id)
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              [
                                _vm._v(
                                  " next （" +
                                    _vm._s(_vm.items.length - 1) +
                                    " +） "
                                ),
                              ]
                            )
                          : _c(
                              "v-btn",
                              _vm._b(
                                {
                                  attrs: { color: "white", icon: "", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dequeue(item.id)
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [_vm._v(" " + _vm._s(item.message) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }