var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "position-x": _vm.x,
        "position-y": _vm.y,
        absolute: "",
        "offset-y": "",
        transition: "scale-transition",
      },
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }