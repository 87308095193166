var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-setting" },
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "drawer",
          attrs: {
            value: _vm.appSetting,
            app: "",
            fixed: "",
            right: "",
            temporary: "",
          },
          on: { input: _vm.handleInput },
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                slot: "prepend",
                height: _vm.appHeaderHeight,
                color: "primary lighten-1",
              },
              slot: "prepend",
            },
            [_c("v-toolbar-title", [_vm._v("Personalize")])],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-subheader", { staticClass: "px-1 my-2" }, [
                        _vm._v(" Developer Mode Settings "),
                      ]),
                      _c("v-switch", {
                        attrs: {
                          "input-value": _vm.appShowHidden,
                          color: "primary",
                          label: "Show hidden components",
                        },
                        on: { change: _vm.toggleAppShowHidden },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("v-subheader", { staticClass: "px-1 my-2" }, [
                        _vm._v(" Navigation bar settings "),
                      ]),
                      _c("v-switch", {
                        attrs: {
                          "input-value": _vm.appPermanentNavigation,
                          color: "primary",
                          label: "Navigation bar fixed left",
                        },
                        on: { change: _vm.toggleAppPermanentNavigation },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("v-subheader", { staticClass: "px-1 my-2" }, [
                        _vm._v(" Exterior "),
                      ]),
                      _c("v-switch", {
                        attrs: {
                          "input-value": _vm.appThemeDark,
                          color: "primary",
                          label: "Dark Mode",
                        },
                        on: { change: _vm.toggleAppThemeDark },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("v-subheader", { staticClass: "px-1 my-2" }, [
                        _vm._v(" Tab Mode "),
                      ]),
                      _c("v-switch", {
                        attrs: {
                          "input-value": _vm.appMultipleTabs,
                          color: "primary",
                          label: "Multiple tabs",
                        },
                        on: { change: _vm.toggleAppMultipleTabs },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("v-subheader", { staticClass: "px-1 my-2" }, [
                        _vm._v(" Theme color settings "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "color-option" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            _vm._l(
                              _vm.colors,
                              function (colorConfig, colorName) {
                                return _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.filterColors.includes(colorName),
                                        expression:
                                          "!filterColors.includes(colorName)",
                                      },
                                    ],
                                    key: colorName,
                                    staticClass:
                                      "app-setting__label flex xs6 pa-1",
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        name: "colorConfig",
                                        type: "radio",
                                      },
                                      domProps: {
                                        checked:
                                          colorConfig.base ===
                                          _vm.appPrimaryColor,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.setAppPrimaryColor(
                                            colorConfig.base
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "app-setting__item bg" },
                                      [
                                        _c("span", { staticClass: "overlay" }, [
                                          _c(
                                            "span",
                                            { staticClass: "material-icons" },
                                            [_vm._v("check")]
                                          ),
                                        ]),
                                        _c("span", {
                                          staticClass:
                                            "app-setting__item-header",
                                          class: colorName,
                                        }),
                                        _c("span", {
                                          staticClass:
                                            "app-setting__item-header",
                                          class: colorName,
                                        }),
                                        _c("span", { staticClass: "white" }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }