var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    _vm._b(
      { attrs: { color: _vm.value ? _vm.color : "transparent", opacity: "1" } },
      "v-overlay",
      _vm.$props,
      false
    ),
    [
      _c("v-progress-circular", {
        attrs: { color: "primary", indeterminate: "", size: "64" },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }