var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "py-0 recursive-menus" },
    [
      _vm._l(_vm.items, function (item) {
        return [
          item.type === "MENU" && (!item.hidden || _vm.showHidden)
            ? _c(
                "v-list-group",
                {
                  directives: [
                    {
                      name: "hasPermission",
                      rawName: "v-hasPermission",
                      value: item.permissions,
                      expression: "item.permissions",
                    },
                  ],
                  key: item.to,
                  class: _vm.sub ? "group-sub" : "",
                  attrs: {
                    value: _vm.$route.path.includes(item.to),
                    "active-class": "primary--text",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prependIcon",
                        fn: function () {
                          return [
                            _c("v-icon", [
                              _vm._v(" " + _vm._s(item.icon) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-list-item-title", [
                              _vm._v(" " + _vm._s(item.text) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  item.children.length
                    ? _c("LayoutRecursiveMenus", {
                        attrs: {
                          showHidden: _vm.showHidden,
                          items: item.children,
                          sub: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          item.type === "VIEW" && (!item.hidden || _vm.showHidden)
            ? _c(
                "v-list-item",
                {
                  directives: [
                    {
                      name: "hasPermission",
                      rawName: "v-hasPermission",
                      value: item.permissions,
                      expression: "item.permissions",
                    },
                  ],
                  key: item.to,
                  attrs: {
                    to: item.to || item.redirect,
                    "active-class": "primary--text",
                    link: "",
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(item.text) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }