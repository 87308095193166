var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-table fill-width fill-height d-flex flex-column" },
    [
      _c("CssStyle", { attrs: { content: _vm.fixedColumnsStyle } }),
      _c("v-form", [_vm._t("search"), _vm._t("headerButtons")], 2),
      _c(
        "div",
        {
          staticClass: "flex-grow-1 overflow-hidden",
          style: { position: "relative" },
        },
        [
          _c("v-data-table", {
            ref: "table",
            staticClass:
              "elevation-0 fill-width fill-height d-flex flex-column overflow-x-hidden",
            attrs: {
              "footer-props": {
                itemsPerPageText: "Number of items per page",
                itemsPerPageOptions: [4, 7, 10, 15, 20, 30, 50],
                showCurrentPage: true,
                showFirstLastPage: true,
              },
              headers: _vm.headers,
              "hide-default-footer": false,
              "item-key": _vm.itemKey,
              items: _vm.items,
              "multi-sort": _vm.multiSort,
              "no-data-text": _vm.loading ? "Loading..." : "No data",
              options: _vm.options,
              "server-items-length": _vm.total || 0,
              "fixed-header": "",
              locale: "zh-cn",
            },
            on: {
              "update:options": function ($event) {
                return _vm.fetch($event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, slot) {
                  return {
                    key: slot,
                    fn: function (scope) {
                      return [_vm._t(slot, null, null, scope)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }