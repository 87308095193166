var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "pr-4",
      attrs: {
        "clipped-left":
          _vm.appPermanentNavigation || _vm.$vuetify.breakpoint.lgAndUp,
        height: _vm.appHeaderHeight,
        app: "",
        color: "primary darken-2",
        dark: "",
        flat: "",
      },
    },
    [
      _c(
        "v-toolbar-title",
        {
          staticClass:
            "ml-0 mr-1 pl-4 d-flex flex-row justify-center align-center",
          staticStyle: { width: "50" },
        },
        [
          _c(
            "v-expand-x-transition",
            [
              _c("v-app-bar-nav-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.appPermanentNavigation,
                    expression: "!appPermanentNavigation",
                  },
                ],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleAppNavigation.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _c("v-spacer"),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "text-none",
                        attrs: { dark: "", text: "" },
                        on: { click: _vm.logout },
                      },
                      on
                    ),
                    [_c("span", [_vm._v(_vm._s(_vm.username))])]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("log out")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { color: "white", icon: "" },
                        on: { click: _vm.toggleAppSetting },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v(" settings "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Personalize ")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          href: _vm.repo,
                          color: "white",
                          icon: "",
                          target: "_blank",
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "feather feather-github",
                          attrs: {
                            fill: "none",
                            height: "24",
                            stroke: "currentColor",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewBox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Github")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }