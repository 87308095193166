var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "fill-width fill-height", attrs: { id: "app" } },
    [
      _c(
        "v-fade-transition",
        { attrs: { "leave-absolute": "", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _c("Toast", { attrs: { dark: _vm.$store.state.setting.appThemeDark } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }